import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Particle from "../Particle";


import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <h1></h1>
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">

              <h1 className="heading-name">

                <strong className="main-name"> TEGO</strong>
              </h1>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col>
              <h2>
               <strong className="main-name">Mission</strong>
              </h2>
              <p>
              Tego's mission is to spearhead research and development efforts aimed at discovering novel cures and treatments for thyroid disease. Through decentralized collaboration and innovation, Tego is committed to leveraging cutting-edge technologies and interdisciplinary expertise to address the complexities of thyroid-related health issues. By fostering an inclusive and transparent ecosystem, Tego aims to accelerate the pace of discovery, ultimately improving the quality of life for individuals affected by thyroid disease worldwide.
              </p>
            </Col>
          </Row>
  
          <Row>
            <h1 style={{ fontSize: "2.6em" }}>
              Tego's <span className="purple"> Purpose </span> 
            </h1>
            <p>
            Tego's purpose is to revolutionize the landscape of thyroid research and development through the power of decentralized networks. By embracing decentralization, Tego aims to democratize access to resources and expertise, thus fostering a collaborative ecosystem where researchers, medical professionals, and patients can collectively drive innovation forward. Tego's mission is to expand opportunities for exploration and discovery in the field of thyroid disease, ultimately striving to accelerate the development of new treatments and cures. Through transparent and inclusive processes, Tego seeks to empower stakeholders to contribute their unique perspectives and insights, leading to breakthroughs that can significantly improve the lives of individuals affected by thyroid conditions around the globe.
            </p>
          </Row>



        </Container>
      </Container>

      
    </section>
  );
}

export default Home;
